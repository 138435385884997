/* eslint-disable import/named */
import React, { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isValid, format } from "date-fns";
import {
  useMediaQuery,
  Box,
  Grid,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ModalUtil from "../../utils/ModalUtil";
import ConsentService from "../../services/ConsentService";
import CAIHCard from "../../components/CAIHCard";
import CAIHLoader from "../../components/CAIHLoader";
import ConsentLoginErrorBlock from "../../components/consentLogin/ConsentLoginErrorBlock";
import ConsentTokenErrorBlock from "../../components/consentLogin/ConsentTokenErrorBlock";
import ConsentLoginSuccessBlock from "../../components/consentLogin/ConsentLoginSuccessBlock";
import PhoneCheckModal from "../../components/consentLogin/PhoneCheckModal";
import LogoLayout from "../../components/LogoLayout";
import CAIHDatepickerLite from "../../components/CAIHDatepickerLite";
import CAIHInfoBlock from "../../components/CAIHInfoBlock";
import { mapPatientData } from "../../services/mappers/mapPatientData";
import { isPatientReachable } from "../../utils/patients/isPatientReachable";
import { isElementEmpty } from "../../utils/isElementEmpty";

import useTokenAuth from "../../hooks/auth/useTokenAuth";
import AuthContext from "../../context/authContext";
import useClinicInformations from "../../hooks/clinic/useClinicinformations";

const ConsentLogin = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const contextId = history.location.hash.substring(1);

  const { setIsAuthenticated } = useContext(AuthContext);

  const {
    isTokenValid,
    healthFacilityId,
    localIpp,
    isFetching,
    globalIntentType,
  } = useTokenAuth(history.location.hash);

  const {
    clinicGHTLogo,
    clinicLogo,
    clinicLegalNotice,
    clinicGtu,
    clinicName,
  } = useClinicInformations(healthFacilityId);

  const setModal = ModalUtil.useModal()[1];

  const [selectedDate, setSelectedDate] = useState(null);
  const [newUser, setNewUser] = useState({});
  const [legalRepresentative, setLegalRepresentative] = useState("no");
  const [isDataNotReachable, setIsDataNotReachable] = useState(false);
  const [birthCheckState, setBirthCheckState] = useState("UNCHECKED");
  const [hasNoConsents, setHasNoConsents] = useState(false);

  const [hasBothConsentType, setHasBothConsentType] = useState(false);
  const [hasMedicalOnly, setHasMedicalOnly] = useState(false);

  const handleDateChange = date => setSelectedDate(date);

  // Birthdate check
  const {
    isFetching: isCheckingBirthdate,

    refetch,
  } = useQuery({
    queryKey: ["searchPatient"],
    retry: 0,
    queryFn: () =>
      ConsentService.getPatientByBirthdate(
        format(new Date(selectedDate), "yyyy-MM-dd"),
        localIpp,
        healthFacilityId,
      ),
    onSuccess: ({ data: user }) => {
      const patientWithDefaultContact = mapPatientData(user);
      setNewUser(patientWithDefaultContact);
      checkConsents();

      // Gestion requete POC
      if (history.location.pathname === "/information") {
        setIsAuthenticated(true);
        return history.push(`/information/complete${history.location.hash}`);
      }
    },
    onError: ({ response }) => {
      const { status, data } = response;
      if (status === 404) return setBirthCheckState("ERROR");
      if (status === 500 && data.errorMessage === "TOO_MANY_ATTEMPTS")
        return setBirthCheckState("TOO_MANY_ATTEMPTS");

      return setBirthCheckState("GENERAL_ERROR");
    },
    enabled: false,
  });

  // Consent check
  const { isFetching: isCheckingConsents, refetch: checkConsents } = useQuery({
    queryKey: ["checkConsents"],
    retry: 0,
    queryFn: () =>
      ConsentService.getAllRequestedConsents(localIpp, healthFacilityId),
    onSuccess: ({ data }) => {
      setBirthCheckState("SUCCESS");

      const hasGdprConsents = data.filter(
        d => d.intentType === "GDPR" && d.status === "REQUESTED",
      ).length;
      const hasMedicalConsents = data.filter(
        d => d.intentType === "MEDICAL" && d.status === "REQUESTED",
      ).length;

      setHasBothConsentType(!!hasGdprConsents && !!hasMedicalConsents);
      setHasMedicalOnly(!hasGdprConsents && hasMedicalConsents);
      setHasNoConsents(!hasGdprConsents && !hasMedicalConsents);
    },
    onError: ({ response }) => {
      const { status, data } = response;
      if (status === 404 && data.errorMessage === "CONSENT_NOT_FOUND")
        return setHasNoConsents(true);

      return setBirthCheckState("GENERAL_ERROR");
    },
    enabled: false,
  });

  const handleNextClick = () => {
    const { cantReach, has2FADisabled } = isPatientReachable(newUser);

    if (has2FADisabled && !cantReach) {
      const consentParams = {
        ipp: localIpp,
        idClinic: newUser.healthFacilityId,
        clinicName,
        clinicLogo,
        clinicGHTLogo,
        clinicLegalNotice,
        clinicGtu,
        name: `${newUser.firstName} ${newUser.lastName}`,
        birthdate: selectedDate,
        legalRepresentative,
        hasBothConsentType,
      };

      if (hasMedicalOnly)
        return history.push("/consent/intervention", consentParams);

      return history.push("/consent/new", consentParams);
    }
    if (!cantReach) {
      return getValidationModal();
    }
    return setIsDataNotReachable(true);
  };

  const getValidationModal = () => {
    setModal({
      isOpen: true,
      title: "Code de vérification",
      hasSeparator: true,
      content: (
        <PhoneCheckModal
          ipp={localIpp}
          idClinic={newUser.healthFacilityId}
          clinicName={clinicName}
          clinicLogo={clinicLogo}
          clinicGHTLogo={clinicGHTLogo}
          clinicLegalNotice={clinicLegalNotice}
          clinicGtu={clinicGtu}
          name={`${newUser.firstName} ${newUser.lastName}`}
          birthdate={selectedDate}
          legalRepresentative={legalRepresentative}
          phone={
            isElementEmpty(newUser.mobileNumber)
              ? newUser.legalRepresentative.mobileNumber
              : newUser.mobileNumber
          }
          hasBothConsentType={hasBothConsentType}
          hasMedicalOnly={hasMedicalOnly}
          context={contextId}
          globalIntentType={globalIntentType}
        />
      ),
      backdropReason: "click",
    });
  };

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
      containerSize="sm"
    >
      <Box>
        {isFetching ? (
          <CAIHLoader />
        ) : (
          <Box>
            <Box mb={3}>
              <Typography
                variant="h1"
                sx={{
                  color: "cobalt.ultramarine",
                  textAlign: isMobile ? "left" : "center",
                  fontSize: isMobile && "24px",
                  textIndent: isMobile && "32px",
                }}
              >
                {t("consentLogin:title")}
              </Typography>
            </Box>

            {!isTokenValid && !isFetching && (
              <ConsentTokenErrorBlock status={404} />
            )}

            {isTokenValid && (
              <Box>
                <CAIHCard hideOnMobile={isMobile}>
                  <Box>
                    <Box>
                      <Box mb={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "cobalt.lynch",
                          }}
                        >
                          {t("consentLogin:birthdate.label")}
                        </Typography>
                      </Box>
                      <Box mb={4}>
                        <CAIHDatepickerLite
                          name="birthdate"
                          disabledPart="FUTURE"
                          onChangeFunc={handleDateChange}
                          selectedDate={selectedDate}
                          required
                          fullWidth
                        />
                      </Box>
                      {/* Error container */}
                      {birthCheckState !== "SUCCESS" &&
                        birthCheckState !== "UNCHECKED" &&
                        !isCheckingBirthdate && (
                          <Box mb={3}>
                            <ConsentLoginErrorBlock
                              errorType={birthCheckState}
                            />
                          </Box>
                        )}

                      {birthCheckState !== "SUCCESS" && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Button
                            fullWidth={isMobile}
                            disabled={
                              birthCheckState === "SUCCESS" ||
                              !isValid(selectedDate) ||
                              isCheckingBirthdate ||
                              isCheckingConsents
                            }
                            onClick={() => {
                              setBirthCheckState("UNCHECKED");
                              refetch();
                            }}
                          >
                            {t("common:next")}
                          </Button>
                        </Grid>
                      )}
                    </Box>

                    {/* Loading Container */}
                    {(isCheckingBirthdate || isCheckingConsents) && (
                      <CAIHLoader label={t("consentLogin:birthdate.loading")} />
                    )}

                    {/* No consents container */}
                    {hasNoConsents && !isCheckingConsents && (
                      <Box mt={2}>
                        <Alert type="info">
                          {t("consentLogin:noConsentsInfo")}
                        </Alert>
                      </Box>
                    )}

                    {/* Succes container */}
                    {birthCheckState === "SUCCESS" && !isCheckingBirthdate && (
                      <Box>
                        <Box mb={2}>
                          <Typography variant="subtitle1">
                            {t("consentLogin:checkboxUser.label")}
                          </Typography>
                        </Box>
                        <ConsentLoginSuccessBlock
                          name={`${newUser.firstName} ${newUser.lastName}`}
                          legalRepresentative={legalRepresentative}
                          setLegalRepresentative={setLegalRepresentative}
                        />

                        <Grid container justifyContent="flex-end">
                          <Button
                            fullWidth={isMobile}
                            onClick={() => handleNextClick()}
                          >
                            {t("common:next")}
                          </Button>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </CAIHCard>
                {birthCheckState === "SUCCESS" &&
                  !isCheckingBirthdate &&
                  !isDataNotReachable && (
                    <Box mt={2}>
                      <CAIHInfoBlock
                        label={t("consentLogin:checkboxUser.warning")}
                      />
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </LogoLayout>
  );
};

export default ConsentLogin;
